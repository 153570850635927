@import '~antd/dist/antd.css';

.profile {
  width: 109px;
  height: 164px;
  margin: 0px 16px 16px 16px;
  float: left;
}

.status{
    color:green;
}

.playerFocusCard{
  width: 100%;
  text-align: left;
  height: 195px;
  border-radius: 10px;
  border-style: solid;
  border-color: blue;
  border-width: 1px;
}

.playerFocusTitle{
  font-size: 25px;
  margin-left: 64px;
  margin-bottom: 4px;
}

.playerFocusTextBold{
  font-weight: 600;
  margin-left: 64px;
}

.playerFocusText{
  margin-left: 16px;
}



