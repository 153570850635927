@import '~antd/dist/antd.css';

.ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .filterBar{
    width: 100%;
    text-align: left;
    height: 80px;
    min-width: 320px;
    border-radius: 10px;
    border-style: solid;
    border-color: blue;
    border-width: 1px;
    font-size: 25px;
    font-weight: 600;
    font-family: Lato;
  }

  .buttonText{
    font-weight: 600;
    font-family: Lato;
  }