@import '~antd/dist/antd.css';

.tickerLogo {
    width: 40px;
    height: 40px;
    }

.tickerPick{
  width: 50px;
  display: flex; 
  justify-content: center; 
  align-items: center;
  flex-direction: column; 
  row-gap: 5px;
}

.tickerCard{
  min-width: 310px;
  height: 88px;
  text-align: left;
  border: 1px solid black;
  font-weight: bold;
}

.tickerCardBordered{
  min-width: 310px;
  height: 88px;
  text-align: left;
  border: 1px solid black;
  font-weight: bold;
  border: unset;
}

.tickerCardMets{
  min-width: 310px;
  height: 88px;
  text-align: left;
  border: 1px solid black;
  font-weight: bold;
  background-color: #002D72;
  color: #FF5910;
  font-weight: bold;
  font-size: 16px;
}

.tickerCardMetsBordered{
  min-width: 310px;
  height: 88px;
  text-align: left;
  border: 1px solid black;
  font-weight: bold;
  background-color: #002D72;
  color: #FF5910;
  font-weight: bold;
  border: unset;
}
