@import "~antd/dist/antd.css";

.timerLogo {
  width: 64px;
  height: 64px;
}

#ribbon .ant-card-body {
  display: flex !important;
}

.timerCard{
  width: 100%;
  text-align: left;
  height: 80px;
  min-width: 320px;
  border-radius: 10px;
  border-style: solid;
  border-color: blue;
  border-width: 1px;
  font-size: 23px;
  font-weight: 600;
  display: flex !important;
}

.timerCardInner{
  display: flex;
  align-items: center; 
  gap: 12px
}

.timerCountdown{
  border: 1px solid #004DBF;
  color: red;
  font-size: 31.25;
  line-height: 38px
}

.timerMets{
  position: absolute;
  right: 12px
}

.timerApprox{
  font-size: 16px;
}

