@import '~antd/dist/antd.css';

@font-face {
    font-family: Lato;
    src: url("fonts/Lato-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: Lato;
    src: url("fonts/Lato-Thin.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
  }
  
  @font-face {
    font-family: Lato;
    src: url("fonts/Lato-ThinItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
  }
  
  @font-face {
    font-family: Lato;
    src: url("fonts/Lato-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: Lato;
    src: url("fonts/Lato-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: Lato;
    src: url("fonts/Lato-Italic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
  }
  
  @font-face {
    font-family: Lato;
    src: url("fonts/Lato-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: LatoLightItalic;
    src: url("fonts/Lato-LightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
  }

  body {
    font-family: Lato;
  }

.applepicker-header {
    background-color: #002d72;
    height: 64px;
}

.applepicker-logo {
    height: 60px;
    margin: 4px;
    float: left;
  }

.logo{
    width: 64px;
    height: 64px;
    margin: 0px 16px 0px 16px;
    float: left;
}

.modeSwitch{
    float: right;
    font-family: Lato;
}
