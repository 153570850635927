@import '~antd/dist/antd.css';

.draftboardLogo {
    width: 50px;
    height: 50px;
    }

.draftBoardCard{
  width: 355px;
  height: 160px;
  text-align: center;
  background: #F5F5F5;
  margin-top: 16px;
  margin-right: 16px;
}

.draftBoardCardBordered{
  width: 355px;
  height: 160px;
  text-align: center;
  background: #F5F5F5;
  margin-top: 16px;
  margin-right: 16px;
  border-style: solid;
  border-color: red;
  border-width: 3px;
}

.draftBoardSubCardEmpty{
  width: 265px;
  height: 130px;
  text-align: center;
}

.draftBoardSubCardSR{
  width: 265px;
  height: 130px;
  text-align: center;
  background: #004DBF;
  color: white;
}

.draftBoardSubCardCol{
  width: 265px;
  height: 130px;
  text-align: center;
  background: #5B8C00;
  color: white;
}

.draftBoardSubCardJC{
  width: 265px;
  height: 130px;
  text-align: center;
  background: black;
  color: white;
}

.draftBoardSubCardHS{
  width: 265px;
  height: 130px;
  text-align: center;
  background: #CF1322;
  color: white;
}

.roundTextDraftBoardCard{
  font-size: 25px;  
  font-weight: 600;
}

.bottomDivDraftBoardCard{
  display: 'flex'
}

.signingBonusTextDraftBoardCard{
  font-size: 16px;
  float: left;
  padding-top: 10px; 
  width: 10%; 
  font-weight: 600;
}

.positionTextDraftBoardCard{
  font-size: 20px;
  float: center;
  padding-top: 8px; 
  width: 90%; 
  font-weight: 600;
}
  