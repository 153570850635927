@import '~antd/dist/antd.css';

.statNameHitter {
    min-width: 40px;
    min-height: 40px;
    text-align: center;
    font-size: 16px; 
    font-weight: 700;
}

.statUnconditionalHitter{
    min-width: 40px;
    min-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 400; 
    float: center;
    padding-top: 7px;
    background: '#FFFFFF';
}

.redStyleHitter{
    min-width: 40px;
    min-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 400; 
    float: center;
    padding-top: 7px;
    background: #F5222D;
}

.yellowStyleHitter{
    min-width: 40px;
    min-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 400; 
    float: center;
    padding-top: 7px;
    background: #FFF566;
}

.greenStyleHitter{
    min-width: 40px;
    min-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 400; 
    float: center;
    padding-top: 7px;
    background: #52C41A;
}