@import '~antd/dist/antd.css';


  .draftBoardContainerCard{
    height: 840px;
    overflow: auto;
    border-radius: 10px;
    border-style: solid;
    border-color: blue;
    border-width: 1px;
    font-family: Lato;
  }