@import '~antd/dist/antd.css';

.statNamePitcher {
    min-width: 60px;
    min-height: 35px;
    text-align: center;
    font-size: 16px; 
    font-weight: 700;  
}

.statUnconditionalPitcher{
    min-width: 60px;
    min-height: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 400; 
    float: center;
    padding-top: 2px;
    background: '#FFFFFF';
}

.redStylePitcher{
    min-width: 60px;
    min-height: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 400; 
    float: center;
    padding-top: 2px;
    background: #F5222D;
}

.yellowStylePitcher{
    min-width: 60px;
    min-height: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 400; 
    float: center;
    padding-top: 2px;
    background: #FFF566;
}

.greenStylePitcher{
    min-width: 60px;
    min-height: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 400; 
    float: center;
    padding-top: 2px;
    background: #52C41A;
}