@import '~antd/dist/antd.css';

.tickerContainer {
    border-radius: 10px;
    border-style: solid;
    border-color: blue;
    border-width: 1px;
    height: 120px;
    display: flex;
    align-items:center;
    }

.ticker{
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    text-align: center;
    display: flex;
    align-items:center;
    gap: 16px;
    margin-left: 12px;
    font-size: 16px;
}

.tickerSelect{
    width: 225px;
    margin-left: 8px;
    margin-right: 8px;
}

.tickerRoundText{
    font-weight: 700;
    min-width: 100px;
}