@import '~antd/dist/antd.css';

  .playerTableCard {
    width: 100%;
    text-align: center;
    border-radius: 10px;
    border-style: solid;
    border-color: blue;
    border-width: 1px;
    height: 520px;
    }