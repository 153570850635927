@import '~antd/dist/antd.css';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td
{
  padding: 8px 8px;
}

.ant-card-body {
  padding: 8px;
}

.ant-collapse-content > .ant-collapse-content-box
{
  background: #f0f2f5;
}




